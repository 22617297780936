@font-face {
	font-family: "regular";
	src: url("./static/fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
	font-family: "medium";
	src: url("./static/fonts/Poppins-Medium.ttf") format("truetype");
}
@font-face {
	font-family: "semi-bold";
	src: url("./static/fonts/Poppins-SemiBold.ttf") format("truetype");
}
@font-face {
	font-family: "bold";
	src: url("./static/fonts/Poppins-Bold.ttf") format("truetype");
}

:root {
	--header-height: 60px;
	--footer-height: 64px;
	--form-field-height: 45px;
	--sidebar_width: 120px;
	--box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);

	--white: #fff;
	--gray: #6c757d;
	--gray-dark: #343a40;
	--success: limegreen;
	--info: #00baf2;
	--warning: #ffc107;
	--danger: #dc3545;
	--light: #f8f9fa;
	--dark: #343a40;
	--font-medium: "medium";
	--font-regular: "regular";
	--font-semi-bold: "semi-bold";
	--font-bold: "bold";
	--smoke: #90a1b733;
	--text-muted: #8392a5;
	--text-smoke: #c7cfda;

	/* light theme */
}

html.light:root {
	--primary-color: #00baf2;
	--secondary-color: #00baf2;
	--bg-primary: #a7b3c41a;
	--bg-secondary: #ffffff;

	--text-primary: #1c2d41;
	--text-secondary: #666666;
	--text-tertiary: #999999;

	--accent-color-1: #4285f4;
	--accent-color-2: #34a853;
	--accent-color-3: #fbbc05;
	--accent-color-4: #ea4335;

	--border-color: #dddddd;
	--separator-color: #e0e0e0;

	--button-background: #00baf2;
	--button-text: #ffffff;
	--button-hover: #00baf2;

	--link-color: #00baf2;
	--visited-link-color: #4e6e8e;

	--success-color: #28a745;
	--warning-color: #ffc107;
	--error-color: #dc3545;
	--info-color: #17a2b8;

	--input-background: #f5f5f5;
	--input-text: #333333;
	--input-placeholder: #999999;
	--input-border: #cccccc;
}
html.dark:root {
	--primary-color: #fbbc05;
	--secondary-color: #4285f4;

	--bg-primary: #000000;
	--bg-secondary: #2e2e2e;

	--text-primary: #ffffff;
	--text-secondary: #aaaaaa;
	--text-tertiary: #777777;

	--accent-color-1: #4285f4;
	--accent-color-2: #34a853;
	--accent-color-3: #ff8800;
	--accent-color-4: #ea4335;

	--border-color: #444444;
	--separator-color: #333333;

	--button-background: #333333;
	--button-text: #ffffff;
	--button-hover: #555555;

	--link-color: #4e6e8e;
	--visited-link-color: #7d98b3;

	--success-color: #4caf50;
	--warning-color: #ffc107;
	--error-color: #ff5722;
	--info-color: #2196f3;

	--input-background: #2e2e2e;
	--input-text: #ffffff;
	--input-placeholder: #777777;
	--input-border: #555555;
}
body {
	background-color: var(--bg-primary);
	color: var(--text-primary);
	font-family: var(--font-regular);
	font-size: 16px;
	/* line-height: 1.5; */
	margin: 0;
	padding: 0;
	transition: background-color 0.2s ease-in-out;
	overflow: hidden;
}

.rounded {
	border-radius: 50px !important;
}

.font-regular {
	font-family: var(--font-regular);
}
.font-medium {
	font-family: var(--font-medium);
}
.font-semi-bold {
	font-family: var(--font-semi-bold);
}
.font-bold {
	font-family: var(--font-bold);
}

.primary-color {
	color: var(--primary-color);
}
.secondary-color {
	color: var(--secondary-color);
}
.bg-primary {
	background-color: var(--bg-primary);
}
.bg-secondary {
	background-color: var(--bg-secondary);
}
.text-primary {
	color: var(--text-primary) !important;
}
.text-secondary {
	color: var(--text-secondary);
}
.text-tertiary {
	color: var(--text-tertiary);
}
.accent-color-1 {
	color: var(--accent-color-1);
}
.accent-color-2 {
	color: var(--accent-color-2);
}
.accent-color-3 {
	color: var(--accent-color-3);
}
.accent-color-4 {
	color: var(--accent-color-4);
}
.border-color {
	border-color: var(--border-color);
}
.separator-color {
	color: var(--separator-color);
}
.button-background {
	background-color: var(--button-background);
}
.button-text {
	color: var(--button-text);
}
.button-hover:hover {
	background-color: var(--button-hover);
}
.link-color {
	color: var(--link-color);
}
.visited-link-color {
	color: var(--visited-link-color);
}
.success-color {
	color: var(--success-color);
}
.warning-color {
	color: var(--warning-color);
}
.error-color {
	color: var(--error-color);
}
.info-color {
	color: var(--info-color);
}

.input-placeholder {
	color: var(--input-placeholder);
}

.bg-mute {
	background-color: var(--bg-mute);
}
.bg-smoke {
	background-color: var(--smoke) !important;
}

.h-10 {
	height: 10%;
}
.h-20 {
	height: 20%;
}
.h-30 {
	height: 30%;
}
.h-40 {
	height: 40%;
}
.h-60 {
	height: 60%;
}
.h-70 {
	height: 70%;
}
.h-80 {
	height: 80%;
}
.h-90 {
	height: 90%;
}

.w-10 {
	width: 10%;
}
.w-20 {
	width: 20%;
}
.w-30 {
	width: 30%;
}
.w-40 {
	width: 40%;
}
.w-60 {
	width: 60%;
}
.w-70 {
	width: 70%;
}
.w-80 {
	width: 80%;
}
.w-90 {
	width: 90%;
}

.vh-10 {
	height: 10vh;
}
.vh-20 {
	height: 20vh;
}
.vh-30 {
	height: 30vh;
}
.vh-40 {
	height: 40vh;
}
.vh-50 {
	height: 50vh;
}
.vh-60 {
	height: 60vh;
}
.vh-70 {
	height: 70vh;
}
.vh-80 {
	height: 80vh;
}
.vh-90 {
	height: 90vh;
}
.vh-100 {
	height: 100vh;
}

.vw-10 {
	width: 10vw;
}
.vw-20 {
	width: 20vw;
}
.vw-30 {
	width: 30vw;
}
.vw-40 {
	width: 40vw;
}
.vw-50 {
	width: 50vw;
}
.vw-60 {
	width: 60vw;
}
.vw-70 {
	width: 70vw;
}
.vw-80 {
	width: 80vw;
}
.vw-90 {
	width: 90vw;
}
.vw-100 {
	width: 100vw;
}

.w-fit-content {
	width: fit-content;
}
.h-fit-content {
	height: fit-content;
}

.fntSz10 {
	font-size: 10px !important;
}
.fntSz11 {
	font-size: 11px !important;
}
.fntSz12 {
	font-size: 12px !important;
}
.fntSz13 {
	font-size: 13px !important;
}
.fntSz14 {
	font-size: 14px !important;
}
.fntSz15 {
	font-size: 15px !important;
}
.fntSz16 {
	font-size: 16px !important;
}
.fntSz17 {
	font-size: 17px !important;
}
.fntSz18 {
	font-size: 18px !important;
}
.fntSz19 {
	font-size: 19px !important;
}
.fntSz20 {
	font-size: 20px !important;
}
.fntSz21 {
	font-size: 21px !important;
}
.fntSz21 {
	font-size: 21px !important;
}
.fntSz22 {
	font-size: 22px !important;
}
.fntSz23 {
	font-size: 23px !important;
}
.fntSz24 {
	font-size: 24px !important;
}
.fntSz25 {
	font-size: 25px !important;
}
.fntSz26 {
	font-size: 26px !important;
}
.fntSz27 {
	font-size: 27px !important;
}
.fntSz28 {
	font-size: 28px !important;
}
.fntSz29 {
	font-size: 29px !important;
}
.fntSz30 {
	font-size: 30px !important;
}
.fntSz40 {
	font-size: 40px !important;
}
.fntSz50 {
	font-size: 50px !important;
}
.fntSz60 {
	font-size: 60px !important;
}
.fntSz70 {
	font-size: 70px !important;
}
.fntSz80 {
	font-size: 80px !important;
}
.fntSz90 {
	font-size: 90px !important;
}
.fntSz100 {
	font-size: 100px !important;
}
.text_wrap {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.box-shadow {
	box-shadow: 0px 5px 35px #8392a54d;
}

.object-fit-cover {
	object-fit: cover;
}
.object-fit-contain {
	object-fit: contain;
}
.object-fit-fill {
	object-fit: fill;
}

.text-line-through {
	text-decoration: line-through;
}
.cursor-text {
	cursor: text;
}

.b-0 {
	bottom: 0;
}
.t-0 {
	top: 0;
}
.l-0 {
	left: 0;
}
.r-0 {
	right: 0;
}
.pointer {
	cursor: pointer;
}
.bold {
	font-weight: bold;
}
.mh-100vh {
	min-height: 100vh;
}

.triangle-up {
	border-color: transparent transparent var(--text-muted) transparent;
	border-style: solid;
	border-width: 0px 10px 16px 10px;
	height: 0px;
	width: 0px;
}
.triangle-down {
	border-color: transparent transparent var(--text-muted) transparent;
	border-style: solid;
	border-width: 0px 10px 16px 10px;
	height: 0px;
	width: 0px;
	transform: rotate(180deg);
}
.triangle-up.active,
.triangle-down.active {
	border-color: transparent transparent var(--success) transparent;
	cursor: auto;
}
.position-center {
	position: absolute;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	left: 0;
}

.aspect-rect {
	aspect-ratio: 2/1;
}
.aspect-square {
	aspect-ratio: 1/1;
}
.aspect-portrait {
	aspect-ratio: 1/2;
}
.line-height-1 {
	line-height: 1;
}
.title-link {
	color: var(--primary);
	cursor: pointer;
	font-weight: bold;
}
.title-link:hover {
	color: var(--secondary);
}
.text-color-secondary {
	color: var(--secondary);
}
.text-color-primary {
	color: var(--primary);
}

.bg-muted {
	background-color: var(--smoke);
}
.text-elips {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-bottom: 0;
}

.underline {
	text-decoration: underline;
}

/* ------------button---------- */
.text-link {
	color: var(--primary-color);
}
.text-link.primary {
	color: #ff8224 !important;
}
.cursor-auto {
	cursor: auto !important;
}
.btn {
	display: inline-block;
	font-weight: 400;
	color: var(--button-text);
	text-align: center;
	vertical-align: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-color: transparent;
	border: 1px solid transparent;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 6px;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, background-image 0.15s ease-in-out, border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
}
.btn-sm {
	padding: 0.25rem 0.5rem;
	font-size: 0.875rem;
	line-height: 1.5;
}
.btn-xs {
	padding: 0.15rem 0.3rem;
	font-size: 0.6rem;
	line-height: 1.5;
	border-radius: 0.2rem;
}

.btn-primary {
	color: var(--button-text);
	background-color: var(--button-background);
}
.btn-secondary {
	color: var(--button-secondary-text);
	background-color: var(--button-secondary-background);
}
.btn.line {
	background: transparent !important;
	border: 1px solid var(--button-background);
	color: var(--button-background);
}
.btn-link {
	color: var(--button-background);
	padding: 0;
	font-size: 15px;
	font-family: "regular";
}
/* ------------button end --------- */

/* --------- checkbox--------- */

[type="radio"]:checked,
[type="radio"]:not(:checked) {
	position: absolute;
	left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
	position: relative;
	padding-left: 28px;
	cursor: pointer;
	line-height: 20px;
	display: inline-block;
	/* color: #666; */
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 18px;
	height: 18px;
	border: 2px solid var(--secondary);
	border-radius: 100%;
	background: #00000000;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
	content: "";
	width: 10px;
	height: 10px;
	background: var(--secondary);
	position: absolute;
	top: 4px;
	left: 4px;
	border-radius: 100%;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
	opacity: 0;
	-webkit-transform: scale(0);
	transform: scale(0);
}
[type="radio"]:checked + label:after {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
}
/* --------radio checkbox end ------------ */

.d-flex {
	display: flex;
}
.justify-content-center {
	justify-content: center;
}
.align-items-center {
	align-items: center;
}
.flex-column {
	flex-direction: column;
}
.justify-content-between {
	justify-content: space-between;
}
.align-items-between {
	align-items: space-between;
}
.justify-content-around {
	justify-content: space-around;
}
.justify-content-start {
	justify-content: flex-start;
}
.align-items-start {
	align-items: flex-start;
}
.justify-content-end {
	justify-content: flex-end;
}
.align-items-end {
	align-items: flex-end;
}
.p-0 {
	padding: 0;
}
.m-0 {
	margin: 0;
}
.ml-auto {
	margin-left: auto;
}
.mx-auto {
	margin-left: auto;
	margin-right: auto;
}
.my-auto {
	margin-top: auto;
	margin-bottom: auto;
}
.m-auto {
	margin: auto;
}
.px-0 {
	padding-left: 0;
	padding-right: 0;
}
.py-0 {
	padding-top: 0;
	padding-bottom: 0;
}
.mx-0 {
	margin-left: 0;
	margin-right: 0;
}
.my-0 {
	margin-top: 0;
	margin-bottom: 0;
}
.p-1 {
	padding: 0.25rem;
}
.m-1 {
	margin: 0.25rem;
}
.px-1 {
	padding-left: 0.25rem;
	padding-right: 0.25rem;
}
.py-1 {
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
}
.mx-1 {
	margin-left: 0.25rem;
	margin-right: 0.25rem;
}
.my-1 {
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
}
.p-2 {
	padding: 0.5rem;
}
.m-2 {
	margin: 0.5rem;
}
.px-2 {
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}
.py-2 {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}
.mx-2 {
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}
.my-2 {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}
.p-3 {
	padding: 1rem;
}
.m-3 {
	margin: 1rem;
}
.px-3 {
	padding-left: 1rem;
	padding-right: 1rem;
}
.py-3 {
	padding-top: 1rem;
	padding-bottom: 1rem;
}
.mx-3 {
	margin-left: 1rem;
	margin-right: 1rem;
}
.my-3 {
	margin-top: 1rem;
	margin-bottom: 1rem;
}
.p-4 {
	padding: 1.5rem;
}
.m-4 {
	margin: 1.5rem;
}
.px-4 {
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}
.py-4 {
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
}
.mx-4 {
	margin-left: 1.5rem;
	margin-right: 1.5rem;
}
.my-4 {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
}
.p-5 {
	padding: 3rem;
}
.m-5 {
	margin: 3rem;
}
.px-5 {
	padding-left: 3rem;
	padding-right: 3rem;
}
.py-5 {
	padding-top: 3rem;
	padding-bottom: 3rem;
}
.mx-5 {
	margin-left: 3rem;
	margin-right: 3rem;
}
.my-5 {
	margin-top: 3rem;
	margin-bottom: 3rem;
}
.mt-0 {
	margin-top: 0;
}
.mt-1 {
	margin-top: 0.25rem;
}
.mt-2 {
	margin-top: 0.5rem;
}
.mt-3 {
	margin-top: 1rem;
}
.mt-4 {
	margin-top: 1.5rem;
}
.mt-5 {
	margin-top: 3rem;
}
.mb-0 {
	margin-bottom: 0;
}
.mb-1 {
	margin-bottom: 0.25rem;
}
.mb-2 {
	margin-bottom: 0.5rem;
}
.mb-3 {
	margin-bottom: 1rem;
}
.mb-4 {
	margin-bottom: 1.5rem;
}
.mb-5 {
	margin-bottom: 3rem;
}
.pt-0 {
	padding-top: 0;
}
.pt-1 {
	padding-top: 0.25rem;
}
.pt-2 {
	padding-top: 0.5rem;
}
.pt-3 {
	padding-top: 1rem;
}
.pt-4 {
	padding-top: 1.5rem;
}
.pt-5 {
	padding-top: 3rem;
}
.pb-0 {
	padding-bottom: 0;
}
.pb-1 {
	padding-bottom: 0.25rem;
}
.pb-2 {
	padding-bottom: 0.5rem;
}
.pb-3 {
	padding-bottom: 1rem;
}
.pb-4 {
	padding-bottom: 1.5rem;
}
.pb-5 {
	padding-bottom: 3rem;
}
.ml-0 {
	margin-left: 0;
}
.ml-1 {
	margin-left: 0.25rem;
}
.ml-2 {
	margin-left: 0.5rem;
}
.ml-3 {
	margin-left: 1rem;
}
.ml-4 {
	margin-left: 1.5rem;
}
.ml-5 {
	margin-left: 3rem;
}
.mr-0 {
	margin-right: 0;
}
.mr-1 {
	margin-right: 0.25rem;
}
.mr-2 {
	margin-right: 0.5rem;
}
.mr-3 {
	margin-right: 1rem;
}
.mr-4 {
	margin-right: 1.5rem;
}
.mr-5 {
	margin-right: 3rem;
}
.pr-0 {
	padding-right: 0;
}
.pr-1 {
	padding-right: 0.25rem;
}
.pr-2 {
	padding-right: 0.5rem;
}
.pr-3 {
	padding-right: 1rem;
}
.pr-4 {
	padding-right: 1.5rem;
}
.pr-5 {
	padding-right: 3rem;
}
.pl-0 {
	padding-left: 0;
}
.pl-1 {
	padding-left: 0.25rem;
}
.pl-2 {
	padding-left: 0.5rem;
}
.pl-3 {
	padding-left: 1rem;
}
.pl-4 {
	padding-left: 1.5rem;
}
.pl-5 {
	padding-left: 3rem;
}

.mt-auto {
	margin-top: auto;
}
.mb-auto {
	margin-bottom: auto;
}
.gap-1 {
	gap: 0.5rem;
}
.gap-2 {
	gap: 1rem;
}
.gap-3 {
	gap: 1.5rem;
}

.mr-auto {
	margin-right: auto;
}
.pt-auto {
	padding-top: auto;
}
.pb-auto {
	padding-bottom: auto;
}
.pl-auto {
	padding-left: auto;
}
.pr-auto {
	padding-right: auto;
}
.text-center {
	text-align: center;
}
.text-left {
	text-align: left;
}
.text-right {
	text-align: right;
}
.text-capitalize {
	text-transform: capitalize;
}

.container {
	padding-left: 1rem;
	padding-right: 1rem;
}
.card {
	padding-left: 12px;
	padding-right: 12px;
	background: var(--bg-secondary);
	border-radius: 6px;
}
.card .title {
	font-weight: bold;
	/* font-size: 18px; */
	margin-bottom: 4px;
}

.text-muted {
	color: var(--text-muted);
}
.text-smoke {
	color: var(--text-smoke);
}
.w-100 {
	width: 100%;
}

.divider {
	height: 1px;
	background-color: #000; /* Set the color of the divider */
	margin: 5px 0; /* Adjust the margin as needed for spacing */
}
.text-danger {
	color: var(--danger);
}
.text-success {
	color: var(--success);
}
.mui-btn {
	background-color: red !important;
	color: white !important;
	border-radius: 4px !important;
	font-size: 14px !important;
}

.btn-dark {
	background: black;
	color: white;
	border-radius: 20px;
}
.btn-white {
	background: white;
	color: black;
	border-radius: 20px;
}
.bg-white {
	background-color: white !important;
}
.text-white {
	color: white !important;
}
.gap-1 {
	gap: 0.5rem;
}
.gap-2 {
	gap: 1rem;
}
.gap-3 {
	gap: 1.5rem;
}
.gap-4 {
	gap: 2rem;
}
.gap-5 {
	gap: 3rem;
}
.bg-dark {
	background: black;
}
.position-relative {
	position: relative;
}

ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
}
ul li {
	padding: 0.5rem;
	margin: 0;
}
ul li:hover,
ul li.active {
	/* background-color: var(--smoke);
	border-radius: 6px; */
}

/** --------------------loader start ----------------------*/
.loader {
	width: 34px;
	aspect-ratio: 1;
	display: grid;
	border-radius: 50%;
	background: linear-gradient(0deg, rgb(0 186 242/50%) 30%, #0000 0 70%, rgb(0 186 242/100%) 0) 50%/8% 100%,
		linear-gradient(90deg, rgb(0 186 242/25%) 30%, #0000 0 70%, rgb(0 186 242/75%) 0) 50%/100% 8%;
	/* background: linear-gradient(0deg, rgb(0 0 0/50%) 30%, #0000 0 70%, rgb(0 0 0/100%) 0) 50%/8% 100%,
		linear-gradient(90deg, rgb(0 0 0/25%) 30%, #0000 0 70%, rgb(0 0 0/75%) 0) 50%/100% 8%; */
	background-repeat: no-repeat;
	animation: l23 1s infinite steps(12);
}
.loader::before,
.loader::after {
	content: "";
	grid-area: 1/1;
	border-radius: 50%;
	background: inherit;
	opacity: 0.915;
	transform: rotate(30deg);
}
.loader::after {
	opacity: 0.83;
	transform: rotate(60deg);
}
@keyframes l23 {
	100% {
		transform: rotate(1turn);
	}
}

/**-----------------loader end ------------------*/
.shadow-card {
	box-shadow: var(--box-shadow);
	background-color: var(--bg-secondary);
	padding: 1rem;
	border-radius: 16px;
	position: relative;
}

.chart-loader {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;
	background: rgba(0, 0, 0, 0.63);
	box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
	border-radius: 10px;
}

.zIndex {
	z-index: 11;
}
