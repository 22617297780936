.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
#root,
.layout,
.App {
	overflow: hidden;
}
.App {
	background: black;
}
.layout {
	max-width: 500px;
	position: relative;
	margin: auto;
	background: white;
}
.layout header {
	height: var(--header-height);
	background-color: var(--bg-secondary);
	z-index: 1000;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
}
.layout .body {
	height: calc(100vh - var(--header-height) - var(--footer-height));
	padding-top: var(--header-height);
	padding-bottom: var(--footer-height);
	overflow-y: auto;
	overflow-x: hidden;
	background-color: var(--bg-primary);
}
#profile .body {
	height: calc(100vh - var(--footer-height));
	padding-top: 0;
}
.layout .body .container {
	padding-bottom: 50px;
}
.layout footer {
	height: var(--footer-height);
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	border-top-right-radius: 30px;
	border-top-left-radius: 30px;
	background-color: var(--bg-secondary);
	z-index: 11;
}
.layout footer .bottom-nav {
	background-color: var(--bg-secondary);
	border-top-right-radius: 30px;
	border-top-left-radius: 30px;
}
.layout footer .bottom-nav .bottom-nav-icon-cont {
	background: #f4f6f7;
	padding: 6px;
	border-radius: 50px;
	aspect-ratio: 1;
	border: 1px solid #eff1f2;
}
.layout footer .bottom-nav .bottom-nav-icon {
	color: #9fa1a4;
}
.bottom-nav button {
	background-color: transparent;
	border: none;
	outline: none;
	color: var(--secondary-text-color);
	cursor: pointer;
}
.Mui-selected .bottom-nav-icon-cont {
	background-color: var(--button-background) !important;
}
.Mui-selected .bottom-nav-icon {
	color: white !important;
}
/* .bottom-nav.add-icon {
    background-color: red !important;
    color: #fff !important;
    margin-top: -25px;
    border: 6px solid;
    border-bottom-color: #e1e8ee;
    border-right-color: #e1e8ee;
    transform: rotate(45deg);
} */

.btn-tag {
	background-color: var(--accent-color-3) !important;
	font-weight: bold !important;
	border-radius: 2px;
	padding: 5px 10px !important;
	line-height: 1 !important;
	height: fit-content !important;
	box-shadow: none;
}
.logo-placeholder {
	width: 100%;
	max-height: 200px;
	cursor: pointer;
	object-fit: contain;
}
.business-logo-input {
	display: none;
}
.sign-placeholder {
	height: 200px;
	background-color: var(--bg-secondary);
	display: flex;
	width: 300px;
	align-items: center;
	justify-content: center;
	font-size: 30px;
	cursor: pointer;
}
#invoice-wrapper {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background: white;
	z-index: 1000;
	height: 100%;
	width: 100%;
	overflow: auto;
}
.profile-pic-img {
	background: var(--smoke);
	object-fit: cover;
	z-index: 999;
	height: calc(var(--header-height) - 24px);
	width: auto;
}
.profile-pic {
	background: var(--primary);
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
}
.profile-pic span {
	color: white !important;
	text-transform: capitalize;
	font-family: medium;
}

.profile-popup {
	list-style: none;
}
.profile-popup li {
	display: flex;
	padding: 10px;
	min-width: 160px;
	cursor: pointer;
}
.profile-popup li:hover {
	background-color: var(--bg-secondary);
}

/* loading component */
.loading_wrapper {
	width: 200px;
	height: 60px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}
.loading_circle {
	width: 20px;
	height: 20px;
	position: absolute;
	border-radius: 50%;
	background-color: #000;
	left: 15%;
	transform-origin: 50%;
	animation: loading_circle 0.5s alternate infinite ease;
}

@keyframes loading_circle {
	0% {
		top: 60px;
		height: 5px;
		border-radius: 50px 50px 25px 25px;
		transform: scaleX(1.7);
	}
	40% {
		height: 20px;
		border-radius: 50%;
		transform: scaleX(1);
	}
	100% {
		top: 0%;
	}
}
.loading_circle:nth-child(2) {
	left: 45%;
	animation-delay: 0.2s;
}
.loading_circle:nth-child(3) {
	left: auto;
	right: 15%;
	animation-delay: 0.3s;
}
.loading_shadow {
	width: 20px;
	height: 4px;
	border-radius: 50%;
	background-color: rgba(0, 0, 0, 0.5);
	position: absolute;
	top: 62px;
	transform-origin: 50%;
	z-index: -1;
	left: 15%;
	filter: blur(1px);
	animation: loading_shadow 0.5s alternate infinite ease;
}

@keyframes loading_shadow {
	0% {
		transform: scaleX(1.5);
	}
	40% {
		transform: scaleX(1);
		opacity: 0.7;
	}
	100% {
		transform: scaleX(0.2);
		opacity: 0.4;
	}
}
.loading_shadow:nth-child(4) {
	left: 45%;
	animation-delay: 0.2s;
}
.loading_shadow:nth-child(5) {
	left: auto;
	right: 15%;
	animation-delay: 0.3s;
}
.loading_wrapper span {
	position: absolute;
	top: 75px;
	font-family: "Poppins";
	font-size: 20px;
	letter-spacing: 12px;
	color: var(--text-color);
	font-weight: bold;
	left: 15%;
}
.loding_hoc_container {
	z-index: 10001;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.25);
	box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
	/* backdrop-filter: blur(4px); */
	/* -webkit-backdrop-filter: blur(4px); */
	/* border-radius: 10px; */
	/* border: 1px solid rgba(0, 0, 0, 0.18); */
	display: flex;
	align-items: center;
	justify-content: center;
}
#loading-hoc .loading_hoc_bg {
	z-index: -1;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
	/* background-color: blue; */
}
.loader_cont {
	background-color: white;
	box-shadow: var(--box-shadow);
	padding: 14px;
	border-radius: 10px;
}
/* -------- loading component ends -------- */

.circular-icon {
	border-radius: 50px;
	padding: 6px;
}

.invoice-header .business-logo {
	/* max-width: 60%; */
}
.business-address {
	word-break: break-all;
}
.table-body .table-row {
	border-bottom: 1px solid lightgrey;
}

.hide-scrollbar::-webkit-scrollbar {
	width: 0; /* Remove scrollbar space */
	background: transparent; /* Optional: just make scrollbar invisible */
}
.input_text_cont {
	width: 100%;
}
.input_text_cont .input_text {
	border: none;
	outline: none;
	font-size: 16px;
	width: 100%;
}
.input_text_cont .input_text:focus,
.input_text_cont .input_text:active {
	border-bottom: 1px solid lightgrey;
}

.expense-card {
	padding: 10px;
	border-radius: 10px;
	position: relative;
	display: flex;
}
.remainder-card {
	border-radius: 16px;
	position: relative;
	display: flex;
	background-color: var(--bg-secondary);
	padding: 10px;
	margin-bottom: 12px;
}
.expense-card .expense_title,
.remainder-card .expense_title {
	max-width: 170px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.spendings_list .position-relative:not(:last-child):after {
	content: "";
	border-bottom: 1px solid var(--smoke);
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
}

.expense-card .category_icon img {
	width: 40px;
	height: 40px;
	object-fit: contain;
	margin-right: 10px;
}
.remainder-card .category_icon img {
	width: 30px;
	height: 30px;
	object-fit: contain;
	margin-right: 10px;
}
.expense-card .expense_account_icon img,
.remainder-card .expense_account_icon img,
.credit_account_icon img {
	width: 20px;
	height: 16px;
	object-fit: contain;
}

.expense-edit-icon {
	position: absolute !important;
	top: 17px !important;
	right: 2px !important;
	display: none !important;
}
.expense-edit-icon img {
	width: 20px;
	height: 20px;
	object-fit: contain;
}
.card-setting-icon {
	position: absolute !important;
	top: 17px !important;
	right: -40px !important;
	display: none !important;
}

.editable_card_item ~ .expense-edit-icon,
.editable_card_item .card-setting-icon {
	display: block !important;
}
.spendings_list {
	background-color: var(--bg-secondary);
	padding: 10px;
	border-radius: 10px;
	margin-bottom: 10px;
}
.spendings_list .expense_amount {
	color: var(--accent-color-4);
}
.spendings_list .expense_amount::before {
	content: "-";
	padding-right: 2px;
	color: var(--secondary-text-color);
	font-weight: 600;
}
.spendings_list .credit_amount {
	color: var(--success);
}
.spendings_list .credit_amount::before {
	content: "+";
	padding-right: 2px;
	color: var(--success);
}
/* ---------------- profile -----------------*/
#profile .top_bg {
	background-color: var(--bg-secondary);
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.total_bal_card {
	background: linear-gradient(180deg, #d6d0fc 0%, #e6d0f3 30%, #f2cdec 60%, #f2cdec 100%);
	padding: 2rem 1.4rem 1rem 1.4rem;
	border-radius: 20px;
	bottom: 0;
	margin-top: 6rem !important;
}
.login_top {
}
.profile_card {
	padding: 1rem 1rem 0.8rem 1rem;
	position: fixed;
	top: 0;
	background: var(--bg-secondary);
	left: 0;
	right: 0;
	box-shadow: var(--box-shadow);
	z-index: 1;
	border-bottom-left-radius: 30px;
	border-bottom-right-radius: 30px;
}
.profile_card .profile_icon {
	border: 2px solid var(--button-background);
	padding: 2px;
	border-radius: 50px;
	height: 40px;
	width: 40px;
	margin-right: 10px;
}
.profile_card .profile_icon img {
	width: 40px;
	height: 40px;
	object-fit: contain;
	border-radius: 50px;
	object-position: top;
}
.profile_card span {
	font-size: 24px;
	font-weight: 600;
	color: var(--text-color);
	/* margin: 0.5rem 0; */
}

.profile_card .right-icons .alert_icon {
	width: 26px;
	height: 26px;
	backdrop-filter: drop-shadow(var(--box-shadow));
}
.alert_icon_badge .MuiBadge-dot {
	top: 3px;
	background: red;
	left: 9px;
	right: 8px;
	bottom: -29px;
	padding: 4px;
	border-radius: 50px;
	border: 2px solid white;
}

.med_bg {
	/* background: linear-gradient(180deg, #fdeaed 0%, #fdeaed 80%, var(--bg-primary) 100%); */
	background-color: var(--bg-secondary);
	/* height: 17vh; */
	padding: 1rem 1rem;
	padding-top: 0.5rem;
}
.month_expense_card {
	background-color: var(--bg-secondary);
	padding: 1rem 1rem;
	border-radius: 16px;
	/* box-shadow: 0px 5px 35px #8392a54d; */
	margin-bottom: -3rem;
}

.bott_bg {
	padding: 0 1rem 1rem 1rem;
	background: linear-gradient(180deg, #ffffff 0%, #ffffff87 10%, #ffffff87 50%, var(--bg-primary) 100%);
	/* background: linear-gradient(180deg var(--bg-secondary) 0%, #ffffff87 40% #ffffff87 100%); */

	/* background-color: var(--bg-secondary); */
}
.account_card {
	background-color: var(--bg-secondary);
	padding: 1rem 1.4rem;
	border-radius: 20px;
	box-shadow: var(--box-shadow);
	margin-bottom: 12px;
}
.account_card .account_icon img {
	width: 34px;
	height: 34px;
	object-fit: contain;
	margin-right: 10px;
}
.account_title {
	line-height: 1;
}
/* ---------------- profile end -----------------*/

/* ----------------- add expense ----------------- */

#add-expense .body {
	height: calc(100vh - var(--footer-height));
	padding-top: 0;
	background-color: var(--bg-secondary);
	overflow: hidden;
}

body::-webkit-scrollbar,
html::-webkit-scrollbar,
.body::-webkit-scrollbar,
*::-webkit-scrollbar {
	display: none !important;
}
#add-expense .top_bg {
	background-color: black;
	height: 14vh;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding: 1rem 1rem 0 1rem;
}
/* .form-cont {
	background-color: var(--bg-secondary);
} */
#add-expense .profile_card span {
	font-size: 20px;
	font-weight: 600;
	margin: 0.5rem 0;
}

.expense-form-cont {
	background-color: var(--bg-secondary);
	padding: 2rem 1.4rem;
	border-top-left-radius: 40px;
	border-top-right-radius: 40px;
	/* min-height: calc(100% - 14vh - var(--footer-height)); */
	height: calc(100vh - 24vh);
	overflow: auto;
}
.expense-footer {
	position: sticky;
	top: calc(100% - var(--header-height));
}
.slider_gate {
	position: absolute;
	top: 6px;
	height: 7px;
	color: red;
	background: var(--text-smoke);
	z-index: 1000;
	width: 84px;
	border-radius: 50px;
	left: 39%;
	cursor: pointer;
}
/* ----------------- add expense end ----------------- */

/* ----------------- input field ----------------- */

.form-group {
	position: relative;
	margin-bottom: 1.2rem;
	display: flex;
	flex-direction: column;
}
.form-group label.form-label {
	color: var(--text-primary);
	font-weight: 500;
	font-family: "regular";
}
.form-group label.error {
	color: var(--error-color);
	font-family: "regular";
	font-weight: 100;
	font-size: 12px;
	line-height: 1.8;
}
.form-group label.error::before {
	content: "ⓘ ";
}
.form-group .form-field {
	padding: 0.5rem 1rem;
	border-radius: 6px;
	border: 1px solid var(--text-smoke);
	outline: none;
	font-size: 1rem;
	color: var(--text-primary);
}
.form-group .form-field.field-invalid,
.form-group .form-field[field-invalid="true"] {
	border-color: var(--error-color);
}
.form-group .form-field:focus {
	/* border: 1px solid var(--accent-color-4); */
}
.form-group .form-field::placeholder {
	color: var(--text-smoke);
	font-size: 0.9rem;
}
.form-group .form-field[type="number"]::-webkit-inner-spin-button,
.form-group .form-field[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
.form-group .form-field[type="number"] {
	-moz-appearance: textfield;
}
.form-group .input-icon {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 1rem;
}
.form-group .input-icon img {
	width: 20px;
	height: 20px;
	object-fit: contain;
}
.form-group .input-icon img:hover {
	cursor: pointer;
}
.form-group .input-icon img:active {
	transform: scale(0.9);
}
.form-group .input-icon img:focus {
	outline: none;
}
.form-group .input-icon img:active {
	transform: scale(0.9);
}
.form-group .input-icon img:focus {
	outline: none;
}
.form-group .input-icon img:active {
	transform: scale(0.9);
}
.form-group .input-icon img:focus {
	outline: none;
}
.form-group .input-icon img:active {
	transform: scale(0.9);
}

/* ----------------- react select ----------------- */
.react-select__control {
	border: 1px solid var(--text-smoke) !important;
	border-radius: 6px !important;
	outline: none !important;
	box-shadow: none !important;
}
.form-group .field-invalid .react-select__control {
	border-color: var(--error-color) !important;
}
.react-select__control:hover {
	border: 1px solid var(--text-smoke);
}
.react-select__control:focus {
	border: 1px solid var(--accent-color-4);
}
.react-select__placeholder {
	color: var(--text-smoke) !important;
	font-size: 14px;
	font-family: var(--font-regular);
	font-weight: 100;
}
.react-select__single-value {
	color: var(--text-color);
	font-size: 14px;
	font-family: var(--font-regular);
	font-weight: 500;
}
/* .react-select__indicator-separator {
	display: none;
} */
.react-select__indicator {
	color: var(--text-smoke);
}
.react-select__indicator:hover {
	color: var(--text-smoke);
}
.react-select__indicator:focus {
	color: var(--accent-color-4);
}
/* ----------------- date input ----------------- */
.date-time-picker input {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	border-radius: 6px !important;
	outline: none !important;
	box-shadow: none !important;
	font-size: 14px;
	font-family: var(--font-regular);
	/* border: 1px solid var(--text-smoke); */
}
/* .date-time-picker, .date-time-picker div, .date-time-picker input {
	outline: none !important;
	box-shadow: none !important;
	border-color: var(--text-smoke) !important;
} */

.date-time-picker.filter-section {
	min-width: 50% !important;
}
/* ----------------- input field end ----------------- */

.profile_close_icon {
	position: absolute !important;
	top: 10px;
	right: 10px;
	cursor: pointer !important;
	z-index: 1;
}

.editable_card_item {
	transform: translateX(-50px);
	z-index: 11;
	background: white;
	box-shadow: var(--box-shadow);
	transition: transform 0.3s ease-in-out;
}
.editable_card_item::after {
	border: none !important;
}
.curve-top {
	border-top-right-radius: 30px;
	border-top-left-radius: 30px;
	background: red;
}
.MuiDrawer-paper.MuiDrawer-paperAnchorBottom {
	border-top-right-radius: 30px !important;
	border-top-left-radius: 30px !important;
}

.drawer-cont {
	background-color: var(--bg-secondary);
	padding: 2rem 1.4rem;
	border-top-left-radius: 40px;
	border-top-right-radius: 40px;
	/* min-height: calc(100% - 14vh - var(--footer-height)); */
	min-height: 10rem;
	overflow: auto;
	position: relative;
}
.drawer-header {
	padding-top: 2rem;
	position: sticky;
	top: 0;
	background-color: var(--bg-secondary);
}

.profile_more_opts li {
	border-radius: 50px;
	padding: 0.2rem 0.5rem;
	cursor: pointer;
	touch-action: pinch-zoom;
	position: relative;
}
.profile_more_opts li:hover,
.profile_more_opts li:active {
	background-color: var(--light);
	border-radius: 50px;
}

.profile_more_opts li:not(:last-child)::after {
	content: "";
	border-bottom: 1px solid var(--smoke);
	width: 80%;
	position: absolute;
	bottom: 0;
	left: 14%;
}
.login-page {
	height: 100vh;
	background-color: white;
}
.login-page .top_bg {
	height: 40vh;
	background-color: #f4d2da;
}
.login-page .bottom_bg {
	margin-top: -50px;
}

.login-page .login-container {
	background-color: white;
	border-top-left-radius: 30px;
	border-top-right-radius: 30px;
	height: 100%;
	padding: 3rem 2rem;
	position: relative;
}

.login-footer {
	display: flex;
	justify-content: center;
}
.login-footer button {
	width: 90%;
	border-radius: 50px;
	box-shadow: var(--box-shadow);
	background-color: #000;
	color: white;
}
.login_banner img {
	width: auto;
	height: 26vh;
	object-fit: contain;
	margin-right: 10px;
}

.account_verified_icon {
	font-size: 12px !important;
	margin-top: -5px;
	margin-left: 5px;
	color: var(--info);
}

.summary_bar {
	background-color: var(--bg-secondary);
	padding: 10px;
	border-radius: 10px;
}

/* ----------------filters--------------- */
.filter-section {
}
.filter-section .title {
	font-family: "medium";
	margin-bottom: 10px;
}
.filter-section-body {
}
.filter-section-body ul {
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
}
.filter-section-body ul li {
	border: 1px solid var(--text-smoke);
	border-radius: 20px;
	padding: 2px 12px;
	font-size: 12.5px;
}
.filter-section-body ul li.active {
	background-color: var(--button-background);
	border-radius: 20px !important;
	color: var(--button-text);
	border-color: var(--button-background);
}
.expense-filter-footer {
	position: fixed;
	bottom: 0px;
	right: 0;
	left: 0;
	padding: 1.5rem;
	background: white;
	padding-top: 5px;
}
.balance-card {
	padding: 1rem;
	width: 50%;
	border-radius: 10px;
}
.balance-card.income {
	background-color: #e5f3f1;
}
.balance-card.expense {
	background-color: #f5e8f1;
}
.btn-more-icon {
	background-color: var(--button-background) !important;
	color: white !important;
	box-shadow: var(--box-shadow);
}

#splash_screen img {
	width: 100vw;
	height: 100vh;
	object-fit: contain;
	box-shadow: none;
	background: white;
}
.bill_paid_text {
	display: flex;
	align-items: center;
	margin-top: -8px;
}
.bill_paid_icon {
	font-size: 15px !important;
	margin-right: 4px;
}
.billing-cycle {
	color: var(--primary-color) !important;
}
.remainder_pay_icon {
	height: 23px;
	margin-right: 10px;
	width: 25px;
}
.apexcharts-legend.apx-legend-position-bottom {
	justify-content: flex-start !important;
}
.back-btn {
	height: 1px !important;
	margin-top: -8px !important;
	margin-left: -10px !important;
}
.ph-icon-cont {
	width: 40px;
	height: 40px;
	border-radius: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
	background-color: whitesmoke;
}
.ph-icon-cont .ph-icon {
	font-size: 28px !important;
}
